import React from "react";
import Container from "../component/Container";
import LeadParagraph from "../component/LeadParagraph";
import Heading from "../component/Heading";
import Title from "../component/Title";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ListGroup from "react-bootstrap/ListGroup"
import { pp } from "../libs/static"
import PropTypes from 'prop-types';
import Nav from "react-bootstrap/Nav"

const PrivacyPolicy = ({ appName , appType, privacyDate}) => {


    return <Container className="mt-4">
        <Row>
            <Col>
                {
                    pp.map(({ componentType, content }, index) => {
                        if (componentType === "title") {
                            return <Title key={index}>{content}</Title>
                        } else if (componentType === "heading") {
                            return <Heading key={index}>{content}</Heading>
                        } else if (componentType === "paragraph") {
                            return <LeadParagraph justify key={index}>{
                            <div dangerouslySetInnerHTML={{ __html: content.replaceAll("[APPNAME]", `<b><i>${appName}</i></b>`)
                                    .replaceAll("[COMPANYNAME]", '<b>Varni Dev</b>')
                                    .replaceAll("[APPTYPE]", appType)
                                    .replaceAll("[PRIVACYDATE]", privacyDate)
                                    .replaceAll("[EMAILADDRESS]",'<b>contact@varnidev.com</b>') }} />
                                
                            }</LeadParagraph>
                        } else if (componentType === "list") {
                            return <ListGroup key={index} variant="flush">
                                {
                                    content.map((data, i) => {
                                        return <ListGroup.Item key={i}>
                                            {data}
                                        </ListGroup.Item>
                                    })
                                }
                            </ListGroup>
                        }else if (componentType === "link") {
                            return <Nav.Link key={index}  href={content.link} className="pl-0 pr-0" >{content.name}</Nav.Link >
                        }
                    })
                }
            </Col>
        </Row>
    </Container>
}

PrivacyPolicy.propTypes = {
    appName: PropTypes.string.isRequired,
    appType: PropTypes.string.isRequired,
    privacyDate: PropTypes.string.isRequired,
};

export default PrivacyPolicy;