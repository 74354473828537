import React from "react";
import {
  Route,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import NavigationBar from "./component/NavigationBar";
import { navigationList } from "./libs/static";
// import { createBrowserHistory } from "history";
import Footer from "./component/Footer";

// const customHistory = createBrowserHistory({
//   // eslint-disable-next-line no-undef
//   basename: process.env.PUBLIC_URL,
// });

// function RouteApp() {
//   return (
//     <>
//       {Object.values(navigationList).map(
//         ({ routePath, component: Component }, index) => (
//           <Route key={index} path={routePath} exact element={<Component />} />
//         )
//       )}
//     </>
//   );
// }

// const UseWithRoute = withRouter(RouteApp);

function App() {
  return (
    <React.Fragment>
      <NavigationBar />
      <div className="min-vh-100">
        <BrowserRouter>
          <Routes>
          {Object.values(navigationList).map(
        ({ routePath, component: Component }, index) => (
          <Route key={index} path={routePath} exact element={<Component />} />
        )
      )}
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default App;
