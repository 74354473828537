import React from "react";
import { Row, Col } from "react-bootstrap";
import Container from "../component/Container";
import { appList } from "../libs/static";
import AppCard from "../component/AppCard";
import PropTypes from 'prop-types';

const Home = (props) => {
    const {match} = props;
    if(match && match.params && match.params.redirect && match.params.redirect === "app"){
        window.location.href = "https://play.google.com/store/apps/details?id=com.varnidev.brightkids"
    }
    return <React.Fragment>
        <Container className="mt-4">
            {
                appList.map((apps, index) => {
                    return <Row key={index}>
                        {   
                            apps.map((appData, keyIndex) => {
                                return <Col key={keyIndex} xs="12" md="6" className="mb-4">
                                    <AppCard {...appData} />
                                </Col>
                            })
                        }
                    </Row>
                })
            }
        </Container>
    </React.Fragment>
}


Home.propTypes = {
    match: PropTypes.any,
};

export default Home;