import React, { useState } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TextInput from "../component/TextInput";
import Button from "../component/Button"
import Container from "../component/Container"
import Title from "../component/Title"
import Heading from "../component/Heading"
import LeadParagraph from "../component/LeadParagraph"
import { Form } from "react-final-form"
import { regexValidationJson } from "../libs/static"
import base64 from 'react-native-base64'
import Alert from 'react-bootstrap/Alert'

const ContactUs = () => {

    const [isFreezeForm, setFreezeForm] = useState(false);
    const [message, setMessage] = useState();

    const onSubmit = (values) => {
        setFreezeForm(true);
        return new Promise((res) => {
            fetch(`https://varnidev.com/api/`, {
                method: "POST",
                body: base64.encode(JSON.stringify(values))
            })
                .then(res => res.json())
                .then(result => {
                    setFreezeForm(false);
                    if (result.status && result.message) {
                        setMessage(result);
                        res({})
                    }
                })
                .catch(() => {
                    res({ })
                })
        })

    }
    const onValidate = (values) => {
        const errors = {};
        if (!values.fullname) {
            errors.fullname = "Please Enter Full Name"
        } else if (values.fullname.length < 3) {
            errors.fullname = "Please Enter Minimum 3 Character"
        }
        if (!values.email) {
            errors.email = "Please Enter Email"
        } else if (!regexValidationJson.email.test(values.email)) {
            errors.email = "Please Enter Valid Email"
        }
        if (!values.subject) {
            errors.subject = "Please Enter Subject"
        } else if (values.subject.length < 3) {
            errors.subject = "Please Enter Minimum 3 Character"
        }
        if (!values.message) {
            errors.message = "Please Enter Message"
        } else if (values.message.length < 5) {
            errors.message = "Please Enter Minimum 5 Character"
        }
        return errors;
    }

    return <Container className="mt-4">
        <Row>
            <Col>
                <Title>Get in touch with us</Title>
            </Col>
        </Row>
        <Row>
            <Col>

                <Row>
                    <Col>
                        <p className="h5 text-justify">Questions? Ideas? Proposals? Projects?</p>
                        <Heading className="text-justify">We want to hear them!</Heading>

                        <LeadParagraph justify>If you would like to know any more about <b>Varni Dev</b> or what we do please email.</LeadParagraph>

                        <p className="h5">contact@varnidev.com</p>
                    </Col>
                </Row>

            </Col>
            <Col md={6} className="mb-3">
                {message && <Alert
                    variant={message.status === "success" ? "success" : "danger"}
                    onClose={() => setMessage()}
                    dismissible
                >
                    {message.message}
                </Alert>}
                <Form
                    onSubmit={onSubmit}
                    validate={onValidate}
                    render={({ handleSubmit, form }) => {
                        return <form onSubmit={(event) => {
                            const promise = handleSubmit(event);
                            promise && promise.then(() => {
                                form.reset();
                            })
                            return promise;
                        }} noValidate>
                            <Row>
                                <TextInput
                                    name="fullname"
                                    label="Full Name *"
                                    placeholder="Full Name *"
                                    type="text"
                                    disabled={isFreezeForm}
                                />
                            </Row>
                            <Row>
                                <TextInput
                                    name="email"
                                    label="Email *"
                                    placeholder="Email *"
                                    type="email"
                                    disabled={isFreezeForm}
                                />
                            </Row>
                            <Row>
                                <TextInput
                                    name="subject"
                                    label="Subject *"
                                    placeholder="Subject *"
                                    type="text"
                                    disabled={isFreezeForm}
                                />
                            </Row>

                            <Row>
                                <TextInput
                                    name="message"
                                    label="Message *"
                                    placeholder="Message *"
                                    type="text"
                                    as="textarea"
                                    rows={5}
                                    disabled={isFreezeForm}
                                />
                            </Row>
                            <Row>
                                <Col className="text-right" >
                                    <Button disabled={isFreezeForm} type="submit" caption={isFreezeForm ? "Please Wait..." : "Submit"} />
                                </Col>
                            </Row>

                        </form>
                    }}
                />
            </Col>
        </Row>
    </Container>

}

export default ContactUs;