import React from "react"
import { Navbar, Nav } from 'react-bootstrap'
import weblogo from '../assets/images/logo/weblogo.png';
import Container from "./Container"

import { navigationList } from "../libs/static"

class NavigationBar extends React.Component {

    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            activeKey: "/"
        }
    }

    render() {
        return <Navbar expand="md" sticky='top' bg="dark" className="navBar">
            <Container >
                <Navbar.Brand href="/">
                    <img
                        alt="Varni Dev"
                        src={weblogo}
                        height="30"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        {
                            Object.values(navigationList)
                            .filter(({top})=>top)
                            .map(({ hrefPath, displayName }, index) => {
                                return <Nav.Link key={index} href={hrefPath}>{displayName}</Nav.Link>
                            })
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    }


}

export default NavigationBar;