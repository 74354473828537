import React from "react"
import { Nav, Row, Col, Stack } from 'react-bootstrap'
import Container from "./Container"
import { navigationList } from "../libs/static"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faFacebook,  faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {

    return <Container fluid className="bg-dark text-white">
        <Container className="pt-4 pb-4">
            <Row>
                <Col md={6} className="text-center text-md-left">
                    <Nav className="flex row justify-content-center justify-content-md-start ml-md-n3">
                        <Stack direction="horizontal">
                        <Nav.Link
                        target="new"
                            className="pt-0 pb-0"
                            href="https://youtube.com/channel/UCwJXk80XaAYsalDVmZCmsqw">
                            <FontAwesomeIcon icon={faYoutube} size="lg" />
                        </Nav.Link>
                        <Nav.Link target="new" className="pt-0 pb-0" href="https://www.facebook.com/Varni-Dev-110166784248505">
                            <FontAwesomeIcon icon={faFacebook} size="lg" />
                        </Nav.Link>
                        <Nav.Link target="new" className="pt-0 pb-0" href="https://www.instagram.com/varnidev/">
                            <FontAwesomeIcon icon={faInstagram} size="lg" />
                        </Nav.Link>
                        <Nav.Link target="new" className="pt-0 pb-0" href="https://twitter.com/VarniDev">
                            <FontAwesomeIcon icon={faTwitter} size="lg" />
                        </Nav.Link>
                        </Stack>
                    </Nav>
                </Col>
                <Col md={6} className="text-center text-md-right">
                    <Nav className="flex-column flex-md-row justify-content-center justify-content-md-end mr-md-n3 mt-4 mt-md-0">
                        {
                            Object.values(navigationList)
                                .filter(({ top, visible }) => !top && visible)
                                .map(({ hrefPath, displayName }, index) => {
                                    return <Nav.Link className="pt-0 pb-0" key={index} href={hrefPath}>{displayName}</Nav.Link>
                                })
                        }
                    </Nav>
                </Col>
            </Row>
        </Container>
    </Container>;
}

export default Footer;