import React from 'react'
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form'
import { Field } from "react-final-form"
import Col from 'react-bootstrap/Col'
const TextInput = ({ label, name, ...otherProps }) => {

    return <Field name={name}>
        {({ input, meta }) => {
            return <Form.Group as={Col} md="12">
                <Form.Label>{label}</Form.Label>
                <Form.Control
                    {...otherProps}
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                    isInvalid={(meta.error && meta.touched)}
                />
                {
                    meta.error && meta.touched && 
                    <Form.Control.Feedback type="invalid" tooltip style={{ left: "unset", right:"15px" }}>
                        {meta.error}
                    </Form.Control.Feedback>
                }

            </Form.Group>
        }}
    </Field>


}

TextInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.any.isRequired,
};

export default TextInput;