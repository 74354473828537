import React from 'react';
import BSContainer from 'react-bootstrap/Container'
import PropTypes from 'prop-types';

const Container = ({ children, className, fluid}) => {
    return <BSContainer className={className} fluid={fluid}>
        {children}
    </BSContainer>
}

Container.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    fluid:PropTypes.bool
};

export default Container;
