import React from "react";
import PropTypes from 'prop-types';

const Title = ({children})=>{
    return <p className="display-4">{children}</p>
}

Title.propTypes = {
    children: PropTypes.any.isRequired,
};

export default Title;