import moment from "moment";

export const createNavigation = (keyName, displayName, component, routePath, hrefPath, top, visible) => {
    return { [keyName]: { displayName, component, routePath, hrefPath, top, visible } }
}

export const createTocJson = (componentType, content) => {
    return { componentType, content }
}   

export const createAppListJson = (appImage, appName, appContent, appLink, appReleased, appUpdate, policyLink) => {
    return { appImage, appName, appContent, appLink, appReleased, appUpdate, policyLink}
}

export const appFormatedDate = (date) => moment(date,"DD/MM/YYYY").format("MMM DD, YYYY");