import React from "react";
import Container from "../component/Container";
import LeadParagraph from "../component/LeadParagraph";
import Heading from "../component/Heading";
import Title from "../component/Title";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ListGroup from "react-bootstrap/ListGroup"
import Nav from "react-bootstrap/Nav"
import { tocJson } from "../libs/static"

const Toc = () => {
    return <Container className="mt-4">
        <Row>
            <Col>
                {
                    tocJson.map(({ componentType, content }, index) => {
                        if (componentType === "title") {
                            return <Title key={index}>{content}</Title>
                        } else if (componentType === "heading") {
                            return <Heading key={index}>{content}</Heading>
                        } else if (componentType === "paragraph") {
                            return <LeadParagraph justify key={index}>
                                <div dangerouslySetInnerHTML={{ __html: content.replaceAll("[COMPANYNAME]", '<b>Varni Dev</b>')
                                    .replaceAll("[EMAILADDRESS]",'<b>contact@varnidev.com</b>') }} />
                            </LeadParagraph>
                        } else if (componentType === "list") {
                            return <ListGroup key={index} variant="flush">
                                {
                                    content.map((data, i) => {
                                        return <ListGroup.Item key={i}>
                                            {data}
                                        </ListGroup.Item>
                                    })
                                }
                            </ListGroup>
                        } else if (componentType === "link") {
                            return <Nav.Link key={index} href={content.link} className="pl-0 pr-0" >{content.name}</Nav.Link >
                        }
                    })
                }
            </Col>
        </Row>
    </Container>
}

export default Toc;