import React from "react";
import Container from "../component/Container"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Title from "../component/Title"
import Heading from "../component/Heading"
import LeadParagraph from "../component/LeadParagraph"

const AboutUs = () => {
    return <Container className="mt-4">
        <Row>
            <Col>
                <Title>Who we are</Title>
            </Col>
        </Row>
        <Row>
            <Col>

                <Heading>Our short history</Heading>

                <LeadParagraph justify>
                    We are a young company driven by innovative & creative thinking and Having technological experience. We are enthusiastic in the fields of Android App
                    Development. It is with this knowledge that we decided it was about time we started serving and utilising our skills in delivering the best
            of solutions.</LeadParagraph>
            </Col>
        </Row>
        <Row>
            <Col>

                <Heading>Mission</Heading>

                <LeadParagraph justify>To improve efficiency and operational productivity of businesses and companies by implementing specific solutions, using latest and best software tools</LeadParagraph>
            </Col>
        </Row>

    </Container>
}

export default AboutUs;