import React from "react";
import PropTypes from 'prop-types';

const Heading = ({children, className})=>{
    return <p className={`h4 ${className}`}>{children}</p>
}

Heading.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
};

export default Heading;