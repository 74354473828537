import React from "react";
import { Card, Stack } from "react-bootstrap";
import Heading from "./Heading";
import LeadParagraph from "./LeadParagraph";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const AppCard = ({
  appImage,
  appName,
  appContent,
  appLink,
  policyLink
}) => {
  return (
    <Card className="h-100 app-card  bg-white">
      <Stack direction="horizontal">
        <Card.Img src={appImage} style={{ width: '12rem' }}  />
        <Card.Body>
          <Heading>{appName}</Heading>
          <LeadParagraph justify className="text-muted font-size-1em">
            {appContent}
          </LeadParagraph>
          <a target="new" href={appLink} className="btn btn-secondary">
            <FontAwesomeIcon icon={faGooglePlay} /> Google Play
          </a>
          <br/>
          <Link  to={policyLink} className="btn btn-link">
            Privacy Policy
          </Link>
          
        </Card.Body>
      </Stack>
    </Card>
  );
};

AppCard.propTypes = {
  appImage: PropTypes.any,
  appName: PropTypes.string,
  appContent: PropTypes.string,
  appLink: PropTypes.string,
  appReleased: PropTypes.string,
  appUpdate: PropTypes.string,
  policyLink: PropTypes.string,
};

export default AppCard;
