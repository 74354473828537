import React from "react";
import BSButton from 'react-bootstrap/Button'
import PropTypes from 'prop-types';

const Button = ({ caption, ...otherProps }) => {
    return <BSButton {...otherProps} >{caption}</BSButton>
}
Button.propTypes = {
    caption: PropTypes.string,
};

export default Button;