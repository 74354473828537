import React from "react";
import PropTypes from 'prop-types';

const LeadParagrapg = ({ children, className, justify }) => {
    const textJustify = justify ? "text-justify" : "";
    return <p className={`lead ${textJustify} ${className}`}>{children}</p>
}

LeadParagrapg.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    justify: PropTypes.bool,
};

export default LeadParagrapg;